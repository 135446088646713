import { Toaster } from "react-hot-toast";
import "./App.css";
import Home from "./features/home/Home";
// import ReactGA from "react-ga4";
import Tnc from "./features/home/Tnc";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

// ReactGA.initialize("G-M0G1R3W3V9");
function App() {
  return (
    <>
      <Toaster />

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tnc" element={<Tnc />} />
        </Routes>
      </Router>

      {/* <Router>
        <div>
          <nav>
            <Link to="/tnc">Tnc</Link>
          </nav>
          <Routes>
            <Route path="/tnc" element={<Tnc />} />
          </Routes>
        </div>
      </Router> */}

      {/* <ApiDetail/> */}

      {/* <Router>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
      </Routes>
    </Router> */}
    </>
  );
}

export default App;
