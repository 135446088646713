import React, { useState } from "react";
import Button from "../../ui/elements/Button";
import { Icon } from "../../ui";
import {
  paymentDetail,
  paymentMethod,
  paymentSource,
  seviceData,
  whyusData,
} from "./mock";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import ContactForm from "../ContactForm/ContactForm";

const Home = () => {

  const [isContactForm, setIsContactForm] = useState(false);

  const onCloseModal = () => {
    setIsContactForm(!isContactForm);
  };
  
  return (
    <>
      <Navbar />
      <div className="herobg">
        <div className=" container">
          <div id="home">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="hero-sec">
                  <h1>
                    Elevate Your Payment Experience with
                    <span className="greentext"> Pluspedirect</span>
                  </h1>
                  <p>
                    Welcome to the future of seamless online payments! At
                    Pluspedirect, we pride ourselves on providing a cutting-edge
                    payment gateway that caters to your convenience and
                    security. Discover a world of effortless transactions and a
                    variety of payment methods tailored to suit your
                    preferences.
                  </p>
                  <a type="button" className="bluebtn" href="#whyus">
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="heroimg">
                  <Icon name="Heroimg" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="greybg">
        <div className="whypadding">
          <div className="container" id="whyus">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-8">
                <div className="payevery text-center">
                  <div className="everytext">
                    <h4>
                      Why Choose Pluspedirect{" "}
                      <span className="greentext"> Payment Gateway?</span>
                    </h4>
                    <Icon name="Lineimg" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row whydata">
              {whyusData.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 my-2 text-center">
                  <div className={`aboutdetail`}>
                    <Icon name={item?.icon} className="img-fluid" />
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container" id="about">
        <div className="everytext text-center">
          <h4>
            Discover Our
            <span className="greentext"> Payment Methods</span>
          </h4>
          <Icon name="Lineimg" className="img-fluid" />
        </div>
        <div className="row align-items-center ">
          <div className="col-lg-6 my-2">
            <div className="whowemain">
              {paymentMethod.map((item, index) => (
                <div className="whowetext">
                  <h5>{item.title}</h5>
                  <p>{item.content}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 my-2">
            <div className="whoweimgsize">
              <Icon name="WhoweAre" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="thresecpadding">
          <div className="everytext text-center">
            <h4>
              How It
              <span className="greentext"> Works?</span>
            </h4>
            <Icon name="Lineimg" className="img-fluid" />
          </div>
          <div className="firstmain">
            <div className="selctmethod">
              <div className="methodtext">
                <p>Select Payment Method</p>
                <i className="fa-solid fa-xmark"></i>
              </div>

              {paymentSource.map((item, index) => (
                <div className="gpayline">
                  <p>{item?.title}</p>
                  <Icon name={item?.icon} className="img-fluid" />
                </div>
              ))}
            </div>
            <div className="choosetext">
              <h3>01</h3>
              <div className="whowetext">
                <h5>Select Payment Method</h5>
                <p>
                  Choose your preferred payment method from our array of
                  options.
                </p>
              </div>
            </div>
            <div className="extra-icon">
              <Icon name="Downimg" className="img-fluid" />
            </div>
          </div>

          <div className="seconndmain">
            <div className="extra-icon">
              <Icon name="DownRight" className="img-fluid" />
            </div>
            <div className="selctmethod">
              <div className="methodtext">
                <p>Enter Payment Details</p>
              </div>

              {paymentDetail.map((item, index) => (
                <div className="gpayline">
                  <p>{item?.title}</p>
                  <Icon name="CopyIcon" className="img-fluid" />
                </div>
              ))}
            </div>
            <div className="choosetext">
              <h3>02</h3>
              <div className="whowetext">
                <h5>Enter Details</h5>
                <p>
                  Input the necessary details for the selected payment method
                </p>
              </div>
            </div>
          </div>

          <div className="thirdmain">
            <div className="selctmethod">
              <div className="succesdetail">
                <Icon name="Success" className="img-fluid" />
                <h5 className="greentext">Payment Success!</h5>
                <h6>₹ 1,000,000</h6>
              </div>

              <div className="trandetail">
                <p>Amount</p>
                <h5>₹475.32</h5>
              </div>
              <div className="trandetail">
                <p>UTR Number</p>
                <h5>012345678901</h5>
              </div>
              <div className="trandetail">
                <p>Transaction ID</p>
                <h5>32149-56298-64891</h5>
              </div>
            </div>
            <div className="choosetext">
              <h3>03</h3>
              <div className="whowetext">
                <h5>Complete Transaction</h5>
                <p>
                  Confirm and complete the transaction swiftly and securely.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container" id="service">
        <div className="servicepadding">
          <div className="everytext text-center">
            <h4>
              Services
              <span className="greentext"> We Offer</span>
            </h4>
            <Icon name="Lineimg" className="img-fluid" />
          </div>
          <div className="row">
            {seviceData.map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6 my-2">
                <div className="seviceoffer">
                  <Icon name={item?.icon} className="img-fluid" />
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="blackbg">
        <div className="container" id="contact">
          <div className="contactmain">
            <div className="grettouchh">
              <h5>Get Started Today!</h5>
              <p>
                Join pluspedirect Payment Gateway and experience the convenience
                of a seamless payment process.message us now to elevate your
                online payment experience.
              </p>
            </div>
            <div>
              <Button
                className="bluebtn  "
                type="button"
                // data-bs-toggle="modal"
                // data-bs-target="#staticBackdrop"
                onClick={() => {
                  onCloseModal();
                }}
              > Message us
              </Button>
              {/* <a href="#contact" type="submit" className="bluebtn">
                Sign up
              </a> */}
            </div>
          </div>
        </div>

        <Footer />
      </div>

      {/* <a href="#">
        <div className="telegrambtn">
          <i className="fa-brands fa-telegram"></i>
        </div>
      </a> */}
      <ContactForm 
        isContactForm={isContactForm}
        onCloseModal={onCloseModal}/>

      {/* <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="modal-dialog modal-dialog-scrollable  d-flex align-items-center"
        >
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title greentext" id="staticBackdropLabel">
                Contact Form
              </h5>
            </div>
            <div className="modal-body">
              <div className="form">
                <Input
                  name="name"
                  type="text"
                  label="Merchant Name"
                  placeholder="Enter Merchant Name"
                  register={register}
                  errors={errors}
                />
                <Input
                  name="mobile_no"
                  type="number"
                  label="Contact Number"
                  placeholder="Enter Mobile Number"
                  register={register}
                  errors={errors}
                />
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Enter Email"
                  register={register}
                  errors={errors}
                />
                <Input
                  name="link"
                  type="text"
                  label="App Link"
                  placeholder="Enter Link"
                  register={register}
                  errors={errors}
                />
                <Input
                  name="telegram"
                  type="text"
                  label="Telegarm ID"
                  placeholder="Enter Telegram ID"
                  register={register}
                  errors={errors}
                />
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button type="submit" className="bluebtn" >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary blueborderbtn"
                data-bs-dismiss="modal"
                onClick={() => reset()}
              >
                Close
              </button>
            </div>
          </div>
        </form>
      </div> */}
    </>
  );
};

export default Home;
