import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Icon } from "../../ui";

const SubmitForm = (props) => {
  const { isSubmitForm, onCloseModalSubmit } = props;

  const onHide = () => {
    onCloseModalSubmit();
  };

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(schema),
  // });

  // const onSubmit = async (values) => {
  //   try {
  //     setIsLoading(true);
  //     const res = await axios.post(
  //       "https://mail-sender.devteamandro.co.in/api/sendmail",
  //       values
  //     );
  //     showToaster(res?.data?.message);
  //     setIsLoading(false);
  //     reset();
  //     onCloseModalSubmit();
  //   } catch (err) {
  //     showToaster(err?.response?.data?.message, "Error");
  //     console.error("Login Error =-=>", err);
  //   }
  // };

  return (
    <Modal
      isSubmitForm={!!isSubmitForm}
      isOpen={isSubmitForm}
      toggle={onHide}
      centered={true}
    >
      <div>
        <form className="modal-dialog modal-dialog-scrollable  d-flex align-items-center">
          <div className="modal-content">
            {/* <div className="modal-header justify-content-end">
              <h5
                onClick={() => {
                  onCloseModalSubmit();
                }}
              >
                <i class="fa-solid fa-xmark"></i>
              </h5>
            </div> */}
            <div className="modal-body">
              <div className="thnktext text-center ">
                <Icon name="SuccessIcon" className="mb-2" />
                <h4>Thank You for Contacting us.</h4>
                <p>
                  We appreciate that you have taken the time to write us. We'll
                  get back to you very soon
                </p>
              </div>
            </div>
            {/* <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-secondary blueborderbtn"
                onClick={() => {
                  onCloseModalSubmit();
                }}
              >
                Close
              </button>
            </div> */}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SubmitForm;
