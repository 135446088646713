import toast from "react-hot-toast";

export const showToaster = (message, type = "Success") => {
  console.log(message,"mes");
  switch (type) {
    case "Error":
      toast.error(message || "Something Went Wrong!", {
        position: "top-right",
      });
      break;
    case "Success":
      toast.success(message, {
        position: "top-right",
      });
      break;
    default:
      toast.success(message, {
        position: "top-right",
      });
      break;
  }
};
