import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Input from "../../ui/forms/Input";
import { showToaster } from "../../helperFunctions";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Icon } from "../../ui";
import SubmitForm from "../SubmitForm/SubmitForm";

const ContactForm = (props) => {
  const { isContactForm, onCloseModal } = props;

  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const onCloseModalSubmit = () => {
    setIsSubmitForm(!isSubmitForm);
  };

  const [isLoading, setIsLoading] = useState(false);

  const onHide = () => {
    onCloseModal();
  };

  const schema = yup.object({
    // name: yup.string().required("Please enter a name"),
    // mobile_no: yup
    //   .string()
    //   .required("Please enter a mobile no")
    //   .max(15, "Please enter mobile no under 15 digit"),
    email: yup.string().required("Please enter a email Id"),
    // link: yup.string().required("Please enter a link"),
    // telegram: yup.string().required("Please enter a telegram"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        ...values,
        subject: "Contact From Pluspedirect Website",
      };
      const res = await axios.post(
        "https://mail-sender.devteamandro.co.in/api/sendmail",
        payload
      );
      showToaster(res?.data?.message);
      setIsLoading(false);
      reset();
      onCloseModal();
      setIsSubmitForm(true);
    } catch (err) {
      showToaster(err?.response?.data?.message, "Error");
      console.error("Login Error =-=>", err);
    }
  };

  return (
    <>
      <Modal
        isContactForm={!!isContactForm}
        isOpen={isContactForm}
        toggle={onHide}
        centered={true}
      >
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="modal-dialog modal-dialog-scrollable  d-flex align-items-center"
          >
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Contact Form
                </h5>
              </div>
              <div className="modal-body">
                <div className="form">
                  <Input
                    name="name"
                    type="text"
                    label="Merchant Name"
                    placeholder="Enter Merchant Name"
                    register={register}
                    errors={errors}
                  />
                  <Input
                    name="mobile_no"
                    type="number"
                    label="Contact Number"
                    placeholder="Enter Mobile Number"
                    register={register}
                    errors={errors}
                  />
                  <Input
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Enter Email"
                    register={register}
                    errors={errors}
                  />
                  <Input
                    name="link"
                    type="text"
                    label="App Link"
                    placeholder="Enter Link"
                    register={register}
                    errors={errors}
                  />
                  <Input
                    name="telegram"
                    type="text"
                    label="Telegarm ID"
                    placeholder="Enter Telegram ID"
                    register={register}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button type="submit" className="bluebtn">
                  {isLoading ? (
                    <Icon name="LoadingGif" className="img-fluid" />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary blueborderbtn"
                  onClick={() => {
                    reset();
                    onCloseModal();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <SubmitForm
        isSubmitForm={isSubmitForm}
        onCloseModalSubmit={onCloseModalSubmit}
      />
    </>
  );
};

export default ContactForm;
