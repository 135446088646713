export const portfolioData = [
  {
    icon: "EveryIconOne",
    title: "Secure Payout Solutions",
    description:
      "Our advanced payout system guarantees the security of transactions, safeguarding your business and customer data from potential threats and fraud.",
  },
  {
    icon: "EveryIconTwo",
    title: "Seamless Transaction Process",
    description:
      "Top up your player wallet or account effortlessly using UPI, UTR, or IMPS, providing a seamless payment experience.",
  },
  {
    icon: "EveryIconThree",
    title: "Customized Payment Options",
    description:
      "Our flexible payment options can be tailored to meet the specific needs of your gaming or high-risk business, providing a personalized payout experience for your customers.",
  },
  {
    icon: "EveryIconFour",
    title: "Dedicated Customer Support",
    description: `Our team of experienced professionals is available round the clock to provide dedicated customer support, addressing or queries regarding payout process.`,
  },
];

export const whyusData = [
  {
    icon: "EveryIconOne",
    title: "Secure and Reliable",
    description:
      "Your security is our top priority. Our robust encryption ensures that your transactions are safeguarded at every step, giving you peace of mind with every payment",
  },
  {
    icon: "EveryIconTwo",
    title: "User-Friendly Interface",
    description:
      "Navigating our platform is a breeze. Our user-friendly interface ensures a hassle-free experience for both seasoned users and those new to online payments",
  },
  {
    icon: "EveryIconThree",
    title: "Customer",
    description:
      "Have questions or need assistance? Our dedicated customer support team is available 24/7 to provide prompt and effective solutions to all your queries",
  },
];

export const whyChooseOne = [
  {
    title: "Unified Bank Aggregation",
    content:
      "Effortlessly handle transactions across multiple banks using our intuitive platform. Our unified dashboard offers a comprehensive view, making it simple to track and manage all your accounts seamlessly.",
  },
  {
    title: "Customized Solutions for High-Risk Businesses",
    content:
      "Navigate the financial landscape confidently with pluspedirect.com. Our banking solutions are crafted to address the unique challenges and opportunities encountered by high-risk businesses. From secure transactions to effective risk management, we've got you covered.",
  },
  {
    title: "Gaming-Centric Interface",
    content:
      "Immerse yourself in a banking experience created for and by gamers. Our gaming-focused interface ensures a smooth and enjoyable banking experience, allowing you to concentrate on your passion – gaming.x",
  },
];

export const paymentMethod = [
  {
    title: "Fully Auto Wallets",
    content:
      "Complete payments effortlessly through popular wallet apps like PhonePe, Google Pay, and Paytm. Our fully automated system guarantees a seamless transaction experience.",
  },
  {
    title: "UPI/UTR",
    content:
      "For swift and accurate processing, input your transaction UTR (Unique Transaction Reference). Our platform is designed for quick and accurate UPI transactions.",
  },
  {
    title: "IMPS",
    content:
      "Experience the convenience of quick deposits via IMPS. Enjoy added flexibility and prompt processing for your transactions.",
  },
  {
    title: "QR-Based Payments",
    content:
      "Simplify the payment process by scanning our QR codes. Make seamless payments through your preferred apps with just a few clicks.",
  },
];

export const paymentSource = [
  {
    title: "Google pay",
    icon: "Gpay",
  },
  {
    title: "Phone pay",
    icon: "Phonepay",
  },
  {
    title: "Paytm",
    icon: "Paytm",
  },
  {
    title: "BHIM",
    icon: "Bhimupi",
  },
  {
    title: "Other app",
    icon: "Other",
  },
];
export const paymentDetail = [
  {
    title: "Account Number",
    icon: "Gpay",
  },
  {
    title: "IFSC Code",
    icon: "Phonepay",
  },
  {
    title: "Account Holder",
    icon: "Paytm",
  },
  {
    title: "Bank Name",
    icon: "Bhimupi",
  },
  {
    title: "Bank Ref.No (Mandatory)",
    placeholder: "Other",
  },
];
export const seviceData = [
  {
    icon: "Serviceone",
    title: "Pluspedirect Wallet",
    description:
      "Opt for our dedicated wallet for a personalized payment experience. Enjoy exclusive benefits, rewards, and faster transactions.",
  },
  {
    icon: "Servicetwo",
    title: "Business Solutions",
    description:
      "Explore our tailored business solutions for merchants. From secure online transactions to customized payment plans, we have your business covered.",
  },
  {
    icon: "Servicethree",
    title: "Integration Services",
    description:
      "Integrate our payment gateway seamlessly into your platform. Our API solutions make the integration process smooth and efficient.",
  },
];
