import React, { useState } from "react";
// import Navbar from "../navbar/Navbar";

const Tnc = () => {
  //   const onCloseModal = () => {
  //     setIsContactForm(!isContactForm);
  //   };
  //   const openModal = () => {
  //     setModalOpen(true);
  //   };
  //   const handleMenuToggle = () => {
  //     setIsMenuOpen(!isMenuOpen);
  //   };
  //   const closeNavbar = () => {
  //     setNavbarOpen(false);
  //   };
  //   const handleLinkClick = () => {
  //     setIsMenuOpen(false);
  //   };
  //   const handleButtonClick = (e) => {
  //     e.preventDefault();
  //     openModal();
  //     closeNavbar();
  //   };
  //   const handleCloseButtonClick = () => {
  //     setModalOpen(false);
  //   };
  const [isModalOpen, setModalOpen] = useState(false);
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    openModal();
    closeNavbar();
  };
  const handleCloseButtonClick = () => {
    setModalOpen(false);
  };
  const onCloseModal = () => {
    setModalOpen(false);
  };
  return (
    //
    <>
      <div
        className={`collapse navbar-collapse justify-content-center position-relative ${
          isMenuOpen ? "show" : ""
        }`}
        id="navbarSupportedContent"
      ></div>
      <form>
        {isModalOpen && (
          <div className="loader-overlay" id="loader">
            <div className="loader-container">
              <div className="modal-content">
                <div className="modal-header justify-content-center">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Terms and Conditions of Service for PluspeDirect
                  </h5>
                </div>
                <div className="modal-body-tnc ">
                  <h2 className="modal-head ">
                    1. Acceptance of Betting iGaming Volume:
                  </h2>
                  <p className="modal-p">
                    {" "}
                    - PluspeDirect exclusively accepts transactions related to
                    betting iGaming volume. Any other trading activities,
                    particularly forex trading, are strictly prohibited under
                    this agreement.
                  </p>
                  <h2 className="modal-head">2. Ticket Size Limits:</h2>
                  <p className="modal-p">
                    {" "}
                    - Transactions facilitated through PluspeDirect are subject
                    to specific ticket size limits. The minimum ticket size is
                    set at INR 500, while the maximum ticket size is capped at
                    INR 100,000. (it may vary)
                  </p>
                  <h2 className="modal-head">
                    3. Use of Checkout Page (Optimization for Success):
                  </h2>
                  <p className="modal-p">
                    {" "}
                    - To optimize transaction success rates, clients are
                    required to utilize PluspeDirect's designated checkout page
                    for all transactions. This ensures a seamless payment
                    process and enhances overall efficiency.
                  </p>
                  <h2 className="modal-head">4. Website URL Disclosure:</h2>
                  <p className="modal-p">
                    {" "}
                    - Clients are required to disclose a comprehensive list of
                    all website URLs from which traffic will be directed to
                    PluspeDirect. This includes websites involved in marketing,
                    advertising, or any activity generating user traffic.
                  </p>
                  <h2 className="modal-head">5. Compliance with Terms:</h2>
                  <p className="modal-p">
                    {" "}
                    - Clients must adhere to all the terms and conditions
                    outlined in this agreement. Failure to comply may result in
                    the termination of services and legal action.
                  </p>
                  <h2 className="modal-head">
                    6. Reporting and Communication:
                  </h2>
                  <p className="modal-p">
                    {" "}
                    - Open communication is essential for the success of this
                    engagement. Clients are obligated to inform PluspeDirect
                    promptly of any changes in business operations, website
                    URLs, or any other relevant information that may impact the
                    services provided.
                  </p>
                  <h2 className="modal-head">7. Confidentiality:</h2>
                  <p className="modal-p">
                    {" "}
                    - Both parties agree to maintain strict confidentiality
                    regarding any proprietary information exchanged during this
                    engagement. This includes, but is not limited to, business
                    strategies, financial data, and technical specifications.
                  </p>
                  <h2 className="modal-head">8. Termination:</h2>
                  <p className="modal-p">
                    {" "}
                    - Either party retains the right to terminate this agreement
                    with written notice if the other party breaches any material
                    term or condition. PluspeDirect reserves the right to
                    suspend or terminate services immediately in the case of
                    non-compliance with the specified terms.
                  </p>
                </div>
                <div className="modal-footer">
                  {/* <button
                    type="button"
                    className="btn btn-secondary blueborderbtn"
                    onClick={() => isModalOpen(false)}
                  >
                    Close
                  </button> */}
                  <button onClick={onCloseModal}>Close Modal</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default Tnc;
