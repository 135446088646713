import { Icon } from "../icons";

const Input = (props) => {
  const {
    className = "",
    inputClassName = "",
    label,
    icon,
    rightIcon,
    errors,
    register,
    name,
    min,
    max,
    lableClassName = "",
    ...rest
  } = props;

  const getRegister = (register) =>
    register && name ? { ...register?.(name) } : {};

  const message = errors?.[name]?.message || "";
  return (
    <div className={`${className} w-fit flex flex-col justify-center relative`}>
      {label ? (
        <label className={`${lableClassName} label text-sm text-ev-darkblue`}>{label}</label>
      ) : null}
      <div className="flex flex-col justify-center">
        {icon ? (
          <Icon className="absolute me-4 text-xl ms-4" name={icon} />
        ) : null}
        <input
          minLength={min}
          maxLength={max}
          name={name}
          className={`form-control ${message ? 'error-border' : ''} ${inputClassName}`}
          {...getRegister(register)}
          {...rest}
        />
        {rightIcon ? (
          <Icon className="absolute right-0 me-4 text-xl error-text" name={rightIcon} />
        ) : null}
      </div>
      {message ? <span className="text-red-500 text-sm error-text">{message}</span> : null}
    </div>
  );
};

export default Input;
