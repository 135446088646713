import React, { useState } from "react";
import { Icon } from "../../ui";

const Footer = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isContactForm, setIsContactForm] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isNavbarOpen, setNavbarOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const closeNavbar = () => {
    setNavbarOpen(false);
  };
  const handleTncLinkClick = (e) => {
    e.preventDefault();
    openModal();
    closeNavbar();
  };

  return (
    <>
      <footer>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <a href="#home">
                    <Icon name="FooterLogo" className="img-fluid mb-3" />
                  </a>
                  <p>
                    We are experts in the field of payment gateways,
                    specializing in meeting the unique requirements of iGaming
                    businesses.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Quick Links</h6>
                  <p>
                    <a href="#home">Home</a>
                  </p>
                  <p>
                    <a href="#service">Service</a>
                  </p>
                  <p>
                    <a href="#about">About Us</a>
                  </p>
                  <p>
                    <a href="#contact">Contact Us</a>
                  </p>
                  <p className="cursore-pointer">
                    <a onClick={handleTncLinkClick}>Terms & Condition</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 my-2">
                <div className="link-detail">
                  <h6>Services</h6>
                  <p>
                    <a to={"/ourwork"}>Pluspedirect Wallet</a>
                  </p>
                  <p>
                    <a to={"/ourwork"}>Business Solutions</a>
                  </p>
                  <p>
                    <a to={"/ourwork"}>Integration Services</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 my-2">
                <div className="link-detail">
                  <h6>Contact Us</h6>
                  <a href={"mailto:support@pluspedirect.com"}>
                    <div className="d-flex align-items-center gap-2">
                      <i class="fa-solid fa-envelope"></i>
                      <p className="mb-0">support@pluspedirect.com</p>
                    </div>
                  </a>
                  <div className="mt-2">
                    <a href={"https://t.me/teresad2024"}>
                      <div className="d-flex align-items-center gap-2">
                        <i class="fa-brands fa-telegram"></i>
                        <p className="mb-0">contact via telegram</p>
                      </div>
                    </a>
                  </div>
                  {/* 
                  <form className="my-2">
                    <a
                      href="https://pay.pluspedirect.com/test"
                      type="submit"
                      className="bluebtn"
                    >
                      Demo
                    </a>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div className="loader-overlay" id="loader">
            <div className="loader-container">
              <div className="modal-content">
                <div className="modal-header justify-content-center">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Terms and Conditions of Service for PluspeDirect
                  </h5>
                </div>
                <div className="modal-body-tnc ">
                  <h2 className="modal-head ">
                    1. Acceptance of Betting iGaming Volume:
                  </h2>
                  <p className="modal-p">
                    - PluspeDirect exclusively accepts transactions related to
                    betting iGaming volume. Any other trading activities,
                    particularly forex trading, are strictly prohibited under
                    this agreement.
                  </p>
                  <h2 className="modal-head">2. Ticket Size Limits:</h2>
                  <p className="modal-p">
                    - Transactions facilitated through PluspeDirect are subject
                    to specific ticket size limits. The minimum ticket size is
                    set at INR 500, while the maximum ticket size is capped at
                    INR 100,000. (it may vary)
                  </p>
                  <h2 className="modal-head">
                    3. Use of Checkout Page (Optimization for Success):
                  </h2>
                  <p className="modal-p">
                    - To optimize transaction success rates, clients are
                    required to utilize PluspeDirect's designated checkout page
                    for all transactions. This ensures a seamless payment
                    process and enhances overall efficiency.
                  </p>
                  <h2 className="modal-head">4. Website URL Disclosure:</h2>
                  <p className="modal-p">
                    - Clients are required to disclose a comprehensive list of
                    all website URLs from which traffic will be directed to
                    PluspeDirect. This includes websites involved in marketing,
                    advertising, or any activity generating user traffic.
                  </p>
                  <h2 className="modal-head">5. Compliance with Terms:</h2>
                  <p className="modal-p">
                    {" "}
                    - Clients must adhere to all the terms and conditions
                    outlined in this agreement. Failure to comply may result in
                    the termination of services and legal action.
                  </p>
                  <h2 className="modal-head">
                    6. Reporting and Communication:
                  </h2>
                  <p className="modal-p">
                    - Open communication is essential for the success of this
                    engagement. Clients are obligated to inform PluspeDirect
                    promptly of any changes in business operations, website
                    URLs, or any other relevant information that may impact the
                    services provided.
                  </p>
                  <h2 className="modal-head">7. Confidentiality:</h2>
                  <p className="modal-p">
                    - Both parties agree to maintain strict confidentiality
                    regarding any proprietary information exchanged during this
                    engagement. This includes, but is not limited to, business
                    strategies, financial data, and technical specifications.
                  </p>
                  <h2 className="modal-head">8. Termination:</h2>
                  <p className="modal-p">
                    - Either party retains the right to terminate this agreement
                    with written notice if the other party breaches any material
                    term or condition. PluspeDirect reserves the right to
                    suspend or terminate services immediately in the case of
                    non-compliance with the specified terms.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary blueborderbtn"
                    onClick={() => setModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </footer>
    </>
  );
};

export default Footer;
